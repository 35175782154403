import React from 'react';
import SwiperCore, { Autoplay, EffectFade, Lazy } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/lazy/lazy.scss';

SwiperCore.use([Autoplay, EffectFade, Lazy]);

class App extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      slideshow: [
        "01_Olivetti_multibox_1920.jpg",
        "02_Maxxi-Doppio-Schermo-01_1920.jpg",
        "03_Frieze-Week-London_1920.jpg",
        "04_Frieze-Week-NY-1_1920.jpg",
        "05_Des_Website_mockup-01_1920.jpg",
        "06_Cindy-Sherman_Cover_1920.jpg",
        "07_Inhedited_01_1920.jpg",
        "08_Inhedited_02_1920.jpg",
        "09_MarioIannelli_mockup-01_1920.jpg",
        "10_Porchettaro_01_1920.jpg",
        "11_Magma_spose_1920.jpg",
        "12_Doppio-Schermo_Face_1920.jpg",
        "13_Zahr-el-Laymoun_01_1920.jpg"
      ],
      start: false
    }

  }

  componentDidMount() {

    this.setState({ start: true });

  }

  render() {

    return (
      <div id="cn" className={ (this.state.start ? 'start' : '') }>

        <div className="displayFlex">

          <h2>Caneva Nishimoto</h2>

          <div className="desc">
            New website coming soon<br /><br />
            London-based, design and art direction
            practice founded by Adriana Caneva and
            Shiro Nishimoto. We specialise in branding
            and editorial projects for commercial and
            cultural clients within the fields of art,
            design, fashion and retail.<br /><br />
            Selected clients<br />
            Brinkworth, Des Lawrence, Frieze Art Fair,
            Galleria Mario Iannelli, Hong Kong Land,
            Istituto Centrale per la Grafica Roma, Lucy
            Williams, MAXXI Museum Rome, National
            Portrait Gallery London, New York University
            Florence, Quodlibet, The Gentlewoman, The
            Gourmand, WaterAid.<br /><br />
            Credits<br />
            Cindy Sherman: Art direction by David Lane
            Frieze Week: Creative direction by David Lane, Art direction by Carol Montpart
            Website photography by Caneva Nishimoto<br /><br />
            Portfolio available upon request<br />
            Email: <a href="mailto:info@caneva-nishimoto.com">info@caneva-nishimoto.com</a>
          </div>

        </div>

        <div className="slideshow">

          <Swiper autoplay={ { delay: 3000 } } speed={ 500 } loop={ true } effect={ 'fade' } lazy={ { loadPrevNext: true } }>
            {this.state.slideshow.map((item, i) => {
              return (
                <SwiperSlide key={ 'slideshow' + i }>
                  <img data-src={ 'img/' + item } className="swiper-lazy" />
                </SwiperSlide>
              )
            })}
          </Swiper>

        </div>

      </div>
    )

  }

}

export default App;
